<template>
  <div>
    <div :ref="`location-hero-slider`" class="glide location-hero-slider">
      <div v-if="totalItems > 1" class="glide__arrows" data-glide-el="controls">
        <button
          class="glide__control glide__arrow--prev"
          data-glide-dir="<"
          name="move slider to the left"
        ></button>
        <button
          class="glide__control glide__arrow--next"
          data-glide-dir=">"
          name="move slider to the right"
        ></button>
      </div>

      <div
        v-if="totalItems > 1"
        class="glide__track lslider-glide-track"
        data-glide-el="track"
      >
        <div class="glide__slides lslider-glide-slides">
          <div v-for="i in totalNumberOfSlides" :key="i" class="glide__slide">
            <slot :name="'slide_' + i"></slot>
          </div>
        </div>
      </div>

      <div v-else class="glide__slides lslider-glide-slides">
        <div class="glide__slide">
          <slot :name="'slide_' + 1"></slot>
        </div>
      </div>
    </div>

    <div class="container">
      <location-slide-counter
        v-if="totalItems > 1"
        :limit="totalItems"
        :current-slide="currentSlide"
      ></location-slide-counter>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";

export default {
  name: "LocationHeroSlider",
  props: {
    totalItems: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data: () => ({
    glide: null,
    currentSlide: 1,
  }),
  computed: {
    totalNumberOfSlides() {
      return parseInt(this.totalItems);
    },
  },
  mounted() {
    this.glide = new Glide(this.$refs[`location-hero-slider`], {
      type: "carousel",
      startAt: 0,
      perView: 1,
    });

    this.setEventListeners();

    this.currentSlide = this.glide.index + 1;
    this.glide.mount();
  },
  methods: {
    setEventListeners() {
      this.glide.on("move", () => {
        this.currentSlide = this.glide.index + 1;
      });

      const resizeEvent = new Event("resize");
      window.dispatchEvent(resizeEvent);
    },
  },
};
</script>
