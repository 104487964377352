<template>
  <div :ref="`host-slider`" class="glide">
    <div class="glide__track" data-glide-el="track">
      <div class="glide__slides">
        <div v-for="i in totalNumberOfSlides" :key="i" class="glide__slide">
          <slot :name="'slide_' + i"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";

export default {
  name: "HostSlider",
  props: {
    totalItems: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data: () => ({
    glide: null,
  }),
  computed: {
    totalNumberOfSlides() {
      return parseInt(this.totalItems);
    },
  },
  mounted() {
    this.glide = new Glide(this.$refs[`host-slider`], {
      type: "carousel",
      perView: 6,
      focusAt: 1,
      peek: { before: 0, after: 50 },
      autoplay: 5000,
      animationTimingFunc: "cubic-bezier(0.5, 1, 0.89, 1)",
      animationDuration: 1000,
      breakpoints: {
        800: {
          perView: 4,
        },
        600: {
          perView: 2,
        },
      },
    });

    const resizeEvent = new Event("resize");
    window.dispatchEvent(resizeEvent);
    this.glide.mount();
  },
};
</script>
