<template>
  <div class="faq-block__inner">
    <div
      v-for="(item, questionIndex) in faqData.questions"
      :key="`faq-items__${questionIndex}`"
      class="faq-block__inner__question"
    >
      <div
        class="faq-block__inner__question__title"
        :class="{ active: activeQuestion === questionIndex }"
        @click="toggleActiveQuestion(questionIndex)"
      >
        {{ item.question }}
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="activeQuestion === questionIndex"
        class="faq-block__inner__question__answer"
        v-html="item.answer"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqBlock",
  props: {
    content: {
      type: [String],
      default: "",
    },
  },
  data() {
    return {
      activeQuestion: -1,
    };
  },
  computed: {
    faqData() {
      return JSON.parse(this.content);
    },
  },
  methods: {
    toggleActiveQuestion(index) {
      if (index === this.activeQuestion) {
        this.activeQuestion = null;
      } else {
        this.activeQuestion = index;
      }
    },
  },
};
</script>
