<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "AnimatedHero",
  mounted() {
    const timeline = gsap.timeline();

    timeline.fromTo(
      ".hero-new__background",
      { scale: 1.25 },
      { scale: 1, duration: 1.5, ease: "power2.out" },
      0
    );
  },
};
</script>
