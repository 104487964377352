<template>
  <div ref="wrapper" class="animated-paragraph" start-animation-offset="0">
    <p ref="text" class="animated-paragraph__text">
      <slot></slot>
    </p>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);

export default {
  name: "AnimatedParagraph",
  props: {
    startAnimationOffset: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.setupTimeline();
  },
  methods: {
    setupTimeline() {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.wrapper,
          start: this.$props.startAnimationOffset,
        },
      });

      const text = new SplitText(this.$refs.text, {
        types: "words",
        wordClass: "word",
      });

      timeline.fromTo(
        text.words,
        { x: -20, autoAlpha: 0, color: "#FFF" },
        {
          x: 0,
          autoAlpha: 1,
          ease: "power2.out",
          duration: 1,
          stagger: { amount: 0.2 },
          onComplete: () => text.revert(),
        },
        0.3
      );
    },
  },
};
</script>
