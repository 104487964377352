<!-- eslint-disable vue/no-v-html -->
<!-- Let op !! - Als je het maximale aantal tabs gaat veranderen van 3 naar meer, dat moet je ook de $maximum-content-tabs variabel in _variables.scss veranderen. -->
<template>
  <div
    class="tabs"
    :class="`tabs--active-index-${tabIndex} tabs--count-${tabCount}`"
  >
    <div class="tabs-control">
      <button
        v-for="index in tabCount"
        :key="`tab-control--${index}`"
        class="tabs-control__button"
        :class="{ 'tabs-control__button--active': index === tabIndex }"
        @click="($event) => setTabIndexTo(index)"
      >
        <slot :name="'name_' + index"></slot>
      </button>
    </div>

    <div class="tabs__items">
      <div v-for="index in tabCount" :key="`tab--${index}`" class="tab-content">
        <transition name="slide-fade">
          <slot v-if="tabIndex === index" :name="'content_' + index"></slot>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentTabs",
  props: {
    tabCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tabIndex: 1,
    };
  },
  methods: {
    setTabIndexTo(i) {
      this.tabIndex = i;
    },
  },
};
</script>
