<template>
  <div id="configurator">
    <div v-if="configuratorActive" class="configurator">
      <div class="configurator__background" @click="close"></div>
      <div class="configurator__content" :class="`bg-${activeStep}`">
        <span
          v-if="activeStep > 1"
          class="configurator__back"
          @click.prevent="activeStep = activeStep - 1"
        ></span>

        <span class="configurator__close" @click="close()">&times; </span>

        <div class="configurator__header">
          <div v-if="activeStep >= 2" class="header__title">
            Plan je reservering
          </div>
          <div v-if="activeStep >= 2" class="header__progress">
            <div
              v-for="n in 4"
              :key="n"
              class="header__progress-item"
              :class="{ active: activeStep >= n }"
            ></div>
          </div>
        </div>

        <div id="configurator-content" class="configurator__steps">
          <!-- Steps -->
          <ConfiguratorWidgetStep v-if="activeStep === 1">
            <template v-slot:question>
              <span class="highlight">Wat</span> wil je doen?
            </template>
            <template v-slot:answers>
              <li class="configurator-step__answer">
                <button @click.prevent="activeStep = 2">
                  Ik wil padellen met vrienden
                </button>
              </li>
              <li class="configurator-step__answer">
                <a href="/lessen">Ik wil een les boeken</a>
              </li>
            </template>
          </ConfiguratorWidgetStep>

          <ConfiguratorWidgetStep v-if="activeStep === 2">
            <template v-slot:question>
              Kies een <span class="highlight">stad</span>
            </template>
            <template v-slot:answers>
              <li
                v-for="(cityObj, cityIndex) in citiesAndLocations"
                :key="cityIndex"
                class="configurator-step__answer"
              >
                <button @click.prevent="setCity(cityObj.city.title)">
                  {{ cityObj.city.title }}
                </button>
              </li>
            </template>
          </ConfiguratorWidgetStep>

          <ConfiguratorWidgetStep v-if="activeStep === 3">
            <template v-slot:question>
              <span class="highlight">Kies</span> een Locatie
            </template>
            <template v-slot:answers>
              <li
                v-for="(filterd_location, locationIndex) in filteredLocations"
                :key="locationIndex"
                class="configurator-step__answer"
              >
                <a
                  v-if="filterd_location.externalURLLocation"
                  target="_blank"
                  :href="filterd_location.externalURLLocation"
                  >{{ location.title }}</a
                >
                <button
                  v-if="!filterd_location.externalURLLocation"
                  class="configurator-step__answer"
                  @click.prevent="
                    setLocation(
                      filterd_location.title,
                      filterd_location.foysHandle
                    )
                  "
                >
                  {{ filterd_location.title }}
                </button>
              </li>
            </template>
          </ConfiguratorWidgetStep>

          <ConfiguratorWidgetStep v-if="activeStep === 4">
            <template v-slot:question>
              <span class="highlight">Loading</span>...
            </template>
          </ConfiguratorWidgetStep>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import EventBus from "../services/event-bus";
import ConfiguratorWidgetStep from "./ConfiguratorWidgetStep.vue";

export default {
  name: "ConfiguratorWidget",
  components: {
    ConfiguratorWidgetStep,
  },
  props: {
    // All cities and their locations (from Craft)
    citiesAndLocations: {
      type: Array,
      required: true,
      default: () => [],
    },
    city: {
      type: String,
      required: false,
      default: null,
    },
    location: {
      type: String,
      required: false,
      default: null,
    },
    locationHandle: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    activeStep: 1,
    configuratorActive: false,
    exitUrl: "/reserveren/court-booking/reservation",
    parameterData: {
      city: "",
      location: "",
      locationHandle: "",

      /**
       * Any date, today or in the future.
       * format: YYYY-MM-DD
       */
      date: "",

      /**
       * Available options:
       * "0": ochtend
       * "1": middag
       * "2": avond
       */
      partOfTheDay: "",
    },
  }),
  computed: {
    /**
     * Locations filtered by selected city.
     * @return {null|*[]}
     */
    filteredLocations() {
      if (this.citiesAndLocations && this.citiesAndLocations.length) {
        const selectedCityAndLocationsObj = this.citiesAndLocations.filter(
          (cityObj) => cityObj.city.title === this.parameterData.city
        );

        return selectedCityAndLocationsObj.length
          ? selectedCityAndLocationsObj[0].children
          : null;
      }

      return null;
    },
    todayDate() {
      return new Date().toISOString().split("T")[0];
    },
    thisWeekDate() {
      const currentDay = new Date();
      const thisWeekDate = new Date(
        currentDay.getTime() + 3 * 24 * 60 * 60 * 1000
      );

      return thisWeekDate.toISOString().split("T")[0];
    },
    nextWeekDate() {
      const currentDay = new Date();
      const nextWeekDate = new Date(
        currentDay.getTime() + 7 * 24 * 60 * 60 * 1000
      );

      return nextWeekDate.toISOString().split("T")[0];
    },
  },
  mounted() {
    this.overwriteParameterData();

    EventBus.$on("toggleConfigurator", () => {
      this.toggleConfigurator();
    });

    EventBus.$on("configuratorData", (configuratorData) => {
      if (configuratorData) {
        this.parameterData.location = configuratorData.location
          ? configuratorData.location
          : null;

        this.parameterData.locationHandle = configuratorData.location
          ? configuratorData.locationHandle
          : null;

        this.parameterData.city = configuratorData.city
          ? configuratorData.city
          : null;

        this.activeStep = configuratorData.step ? configuratorData.step : 1;
      }

      this.overwriteParameterData();
    });

    // Check if ?widget=open
    const urlParams = new URLSearchParams(window.location.search);
    const widgetState = urlParams.get("widget");
    if (widgetState === "open") {
      this.toggleConfigurator();
    }
  },
  methods: {
    toggleConfigurator() {
      this.configuratorActive ^= true;
      const content = document.querySelector(".configurator__steps");
      if (!this.configuratorActive && content) {
        return enableBodyScroll(content);
      }
      this.$nextTick(() => {
        // Wait until DOM is updated
        const content = document.querySelector(".configurator__steps");
        if (this.configuratorActive) {
          EventBus.$emit("toggleMenu", false);
          return disableBodyScroll(content);
        }
      });
    },
    overwriteParameterData() {
      if (
        (this.location || this.parameterData.location) &&
        (this.locationHandle || this.parameterData.locationHandle)
      ) {
        this.activeStep = 4;
        this.parameterData.date = this.todayDate;
        this.parameterData.partOfTheDay = "---";
        this.parameterData.location = this.location
          ? this.location
          : this.parameterData.location;
        this.parameterData.locationHandle = this.locationHandle
          ? this.locationHandle
          : this.parameterData.locationHandle;

        this.setLocation(
          this.parameterData.location,
          this.parameterData.locationHandle
        );
      } else if (this.city || this.parameterData.city) {
        this.activeStep = 3;
        this.parameterData.city = this.city
          ? this.city
          : this.parameterData.city;
      }
    },
    setCity(city = "") {
      this.parameterData.city = city;
      this.activeStep = 3;
    },
    setLocation(location = "", locationHandle = "") {
      this.parameterData.location = location;
      this.parameterData.locationHandle = locationHandle;

      this.setDefaultDateAndPartOfDay();
      this.openReservationModuleWindow();
    },
    setDefaultDateAndPartOfDay() {
      this.parameterData.partOfTheDay = "---";
      this.parameterData.date = this.todayDate;
    },
    openReservationModuleWindow() {
      let exitUrlWithParams = "";

      if (this.parameterData.date) {
        exitUrlWithParams = `${this.exitUrl}?daypart=${this.parameterData.partOfTheDay}&date=${this.parameterData.date}&location=${this.parameterData.locationHandle}`;
      } else {
        exitUrlWithParams = `${this.exitUrl}?location=${this.parameterData.locationHandle}`;
      }

      location.href = exitUrlWithParams;
    },
    close() {
      EventBus.$emit("toggleConfigurator");
    },
  },
};
</script>
