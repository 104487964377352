<template>
  <div class="location-dropdown">
    <div class="location-dropdown__title-container">
      <h1 class="location-dropdown__title">
        <a href="/locaties">{{ currentLocation }}</a>
      </h1>

      <h2
        :class="
          open
            ? 'location-dropdown__subtitle is-open'
            : 'location-dropdown__subtitle'
        "
      >
        {{ currentSubLocation }}
      </h2>
    </div>

    <div class="location-dropdown__toggle-container">
      <button
        :class="
          open
            ? 'location-dropdown__toggle is-open'
            : 'location-dropdown__toggle'
        "
        @click="open = !open"
      ></button>
    </div>

    <div v-if="list" class="location-dropdown__list-container">
      <transition name="location-dropdown__background">
        <div v-if="open" class="location-dropdown__background"></div>
      </transition>

      <transition name="location-dropdown__list">
        <ul
          v-if="open"
          name="location-dropdown__list"
          :style="{ '--total': list.length }"
          class="location-dropdown__list container"
        >
          <li
            v-for="(item, i) in list"
            :key="item.title"
            :style="{ '--i': i }"
            class="location-dropdown__list-item"
          >
            <a :href="item.url" class="location-dropdown__link">
              {{ item.title }}
            </a>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationDropdown",
  props: {
    location: {
      type: String,
      default: "",
      required: true,
    },
    subLocations: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    currentLocation() {
      return this.location;
    },
    currentSubLocation() {
      const activeItem = this.subLocations.find((item) => item.active);
      return activeItem.title;
    },
    list() {
      return this.subLocations.reduce(
        (acc, item) => (item.active ? [item, ...acc] : [...acc, item]),
        []
      );
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  methods: {
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.open = false;
      }
    },
  },
};
</script>
