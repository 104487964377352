<template>
  <div class="blog">
    <h1>Blog</h1>
    <LoadingState v-if="loading" />
    <ServerErrorState v-if="error" :error-obj="error" />
    <template v-if="allBlogItems && allBlogItems.length">
      <div
        v-for="(blogItem, blogItemIndex) in allBlogItems"
        :key="blogItemIndex"
        class="blog__card"
      >
        <h2>
          <a :href="blogItem.uri">{{ blogItem.name }}</a>
        </h2>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="blogItem.translations[0].shortDescription"></div>
        <img :src="blogItem.attachments[0].url" alt="img alt tag joe" />
        <p>{{ blogItem.attachments[0].url }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import { foysApi } from "../services/api";
import LoadingState from "./LoadingState.vue";
import { parseFoysBlogItem } from "../services/utils";
import ServerErrorState from "./ServerErrorState.vue";

export default {
  name: "BlogOverviewList",
  components: {
    LoadingState,
    ServerErrorState,
  },
  data: () => ({
    allBlogItems: null,
    loading: false,
    error: false,
  }),
  async created() {
    await this.getAllBlogItems();
  },
  methods: {
    async getAllBlogItems() {
      this.error = false;
      this.loading = true;

      await foysApi
        .get(foysApi.endpoints.news.getAll)
        .then(
          (res) =>
            (this.allBlogItems = res.data
              ? res.data.map(parseFoysBlogItem)
              : null)
        )
        .catch((err) => (this.error = err.response))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.blog {
  margin: 5rem auto 2rem auto;
  padding: 1rem;
  max-width: 1000px;

  h1 {
    margin-bottom: 2rem;
  }

  &__card {
    margin-bottom: 2rem;
  }
}
</style>
