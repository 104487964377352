<template>
  <div
    ref="menu"
    class="mobile-menu container"
    :class="{ 'mobile-menu--open': isOpen }"
  >
    <slot v-if="loaded"></slot>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import EventBus from "./../services/event-bus";

export default {
  name: "NavigationMenu",
  data() {
    return {
      isOpen: false,
      loaded: true,
    };
  },
  mounted() {
    EventBus.$on("toggleMenu", (isOpen) => {
      this.isOpen = isOpen;
      if (isOpen) {
        return disableBodyScroll(this.$refs.menu);
      }
      return enableBodyScroll(this.$refs.menu);
    });
    this.loaded = true;
  },
};
</script>
