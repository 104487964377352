<template>
  <div :ref="`vue-slider`" class="glide">
    <div class="glide__track" data-glide-el="track">
      <div class="glide__slides">
        <div v-for="i in totalNumberOfSlides" :key="i" class="glide__slide">
          <slot :name="'slide_' + i"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";

export default {
  name: "VueSlider",
  props: {
    totalItems: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data: () => ({
    glide: null,
  }),
  computed: {
    totalNumberOfSlides() {
      return parseInt(this.totalItems);
    },
  },
  mounted() {
    this.glide = new Glide(this.$refs[`vue-slider`], {
      type: "carousel",
      perView: 3,
      startAt: 1,
      focusAt: "center",
      autoplay: 5000,
      animationTimingFunc: "cubic-bezier(0.5, 1, 0.89, 1)",
      animationDuration: 1000,
    });

    this.glide.mount();
    const resizeEvent = new Event("resize");
    window.dispatchEvent(resizeEvent);
  },
};
</script>
