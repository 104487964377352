<template>
  <div class="item__content">
    <slot></slot>

    <div v-if="lessonOptions.length > 1" class="item__options">
      <div
        v-for="(i, index) in lessonOptions"
        :key="`lesson-type__${index}`"
        class="item__option"
      >
        <label
          class="radio__container"
          :for="`radio-${randomIndex}__${i.param}`"
        >
          <input
            :id="`radio-${randomIndex}__${i.param}`"
            v-model="selectedLessonType"
            class="radio__input"
            type="radio"
            :value="i.param"
          />
          <span class="radio__marker"></span>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p class="radio__text" v-html="i.title"></p>
        </label>
      </div>
    </div>

    <div
      v-if="lessonRequestPageUrl && selectedLessonType"
      class="item__request-link"
    >
      <a
        :href="`${lessonRequestPageUrl}?type=${lessonType}&pakket=${selectedCleanUrl}`"
      >
        <div class="btn btn__white btn__full-width">
          {{ requestBtnText }}
        </div>
      </a>
    </div>

    <div v-if="ctas" class="item__request-link">
      <a v-for="(cta, i) in ctas" :key="`cta--${i}`" :href="`${cta.url}`">
        <div class="btn btn__white btn__full-width">
          {{ cta.text }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LessonSelection",
  props: {
    lessonType: {
      type: String,
      required: true,
    },
    lessonOptions: {
      type: Array,
      required: true,
    },
    lessonRequestPageUrl: {
      type: String,
      required: true,
    },
    ctas: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      randomIndex: null,
      selectedLessonType: null,
    };
  },
  computed: {
    selectedCleanUrl() {
      return this.selectedLessonType.replace(/\s+/g, "+").toLowerCase();
    },
    currentLessonType() {
      return this.lessonOptions.filter((obj) => {
        return obj.param === this.selectedLessonType;
      });
    },
    requestBtnText() {
      const self = this;
      if (self.currentLessonType.length) {
        return `${self.currentLessonType[0].btnText}`;
      }
      return null;
    },
  },
  mounted() {
    this.randomIndex = Math.floor(Math.random() * 99999999);
    if (this.lessonOptions.length > 0) {
      this.selectedLessonType = this.lessonOptions[0].param;
    }
  },
};
</script>
