<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="dropdownLinkList && dropdownLinks[selected]"
    class="dropdown-links__container"
  >
    <div v-if="title" class="dropdown__title">{{ title }}</div>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
      <div
        class="selected"
        :class="{ open: open }"
        @click="open = !open"
        v-html="dropdownLinks[selected].link.text"
      ></div>

      <div class="items" :class="{ selectShow: open }">
        <div
          v-for="(i, index) of dropdownLinkList"
          :key="`dropdown-option--${index}`"
          :class="{ active: i.active }"
          @click="switchItem(index)"
          v-html="i.link.text"
        >
          {{ i.link.textCleaned }}
        </div>
      </div>
    </div>

    <div
      v-if="dropdownLinks[selected].text"
      class="text"
      v-html="dropdownLinks[selected].text"
    ></div>
  </div>
</template>

<script>
export default {
  name: "DropdownLinks",
  props: {
    title: {
      type: String,
      required: true,
    },
    dropdownLinks: {
      type: Array,
      required: true,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected: null,
      open: false,
    };
  },
  computed: {
    dropdownLinkList() {
      const list = [...this.dropdownLinks];
      list.map((x, index) => (x.active = index === this.selected));
      return list;
    },
  },
  mounted() {
    this.selected = this.activeIndex;
  },
  methods: {
    switchItem(i) {
      this.selected = i;
      this.open = false;
      this.redirectToUrl();
    },
    redirectToUrl() {
      if (this.dropdownLinks[this.selected].link.target) {
        window
          .open(this.dropdownLinks[this.selected].link.url, "_blank")
          .focus();
      } else {
        window.location.href = this.dropdownLinks[this.selected].link.url;
      }
    },
  },
};
</script>
