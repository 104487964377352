<template>
  <div v-if="errorObj">
    <p>Oeps, er ging iets mis.</p>
    <p>Status: {{ errorObj.status }} - {{ errorObj.statusText }}</p>
    <p>Meldingen:</p>
    <template
      v-if="errorObj.data && errorObj.data.error && errorObj.data.error.length"
    >
      <p v-if="isString(errorObj.data.error)">
        {{ errorObj.data.error }}
      </p>
      <ul v-else>
        <li
          v-for="(errorMessage, errorId) in errorObj.data.error"
          :key="errorId"
        >
          {{ errorMessage }}
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "ServerErrorState",
  props: {
    errorObj: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
  },
  methods: {
    isString(val) {
      return typeof val === "string" || val instanceof String;
    },
  },
};
</script>
