<template>
  <div ref="container" class="parallax-image">
    <div ref="image" class="parallax-image__mask">
      <parallax-container :config="config">
        <slot></slot>
      </parallax-container>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ParallaxContainer from "./ParallaxContainer.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "ParallaxImage",
  components: ParallaxContainer,
  props: {
    wrapper: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      config: {
        speed: 1,
        relativeToWrapper: true,
        wrapper: ".parallax-image",
      },
    };
  },
};
</script>
