<template>
  <div class="floating-cta__inner">
    <div class="floating-cta__inner__location">
      <div class="location__city">
        {{ floatingCtaData.title }}
      </div>
      <div class="location__club">
        {{ floatingCtaData.subtitle }}
      </div>
    </div>
    <div class="floating-cta__inner__cta">
      <div class="cta__text">
        {{ floatingCtaData.text }}
      </div>
      <a
        v-if="
          floatingCtaData.button.type === 'cta' && floatingCtaData.button.url
        "
        :target="floatingCtaData.button.target"
        :href="floatingCtaData.button.url"
        class="cta__button btn btn__green btn__borderless"
      >
        {{ floatingCtaData.button.buttonText }}
      </a>
      <open-configurator-button
        v-if="floatingCtaData.button.type === 'configurator'"
        :button-text="floatingCtaData.button.buttonText"
        classes="cta__button btn btn__green btn__borderless"
        :configurator-data="floatingCtaData.button.locationData"
      >
      </open-configurator-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatingCta",
  props: {
    content: {
      type: [String],
      default: "",
    },
  },
  data() {
    return {
      hidden: false,
    };
  },
  computed: {
    floatingCtaData() {
      return JSON.parse(this.content);
    },
  },
  created() {
    this.setScroll();
  },
  methods: {
    setScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },
    handleScroll() {
      if (window.scrollY < 300) {
        this.hidden = true;
      } else {
        this.hidden = false;
      }

      if (!this.hidden) {
        document
          .querySelector(".floating-cta")
          .classList.add("floating-cta-visible");
      } else {
        document
          .querySelector(".floating-cta")
          .classList.remove("floating-cta-visible");
      }
    },
  },
};
</script>
