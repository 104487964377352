<template>
  <div ref="container" class="animated-image">
    <div ref="image" class="animated-image__mask">
      <parallax-container :config="config" breakpoint="1024px">
        <slot></slot>
      </parallax-container>
    </div>

    <div ref="background" class="animated-image__background"></div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ParallaxContainer from "./ParallaxContainer.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "AnimatedImage",
  components: ParallaxContainer,
  props: {
    wrapper: {
      type: String,
      required: true,
    },
    startAnimationOffset: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      config: {
        speed: 0.5,
        relativeToWrapper: true,
        wrapper: this.$refs.container,
      },
    };
  },
  mounted() {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.$props.wrapper,
        start: this.$props.startAnimationOffset,
      },
    });

    timeline.fromTo(
      this.$refs.image,
      { y: 200, autoAlpha: 0 },
      { y: 0, autoAlpha: 1, duration: 1, ease: "power2.out" },
      0.5
    );

    timeline.fromTo(
      this.$refs.background,
      { y: 200, scaleY: 0, autoAlpha: 0 },
      { y: 0, scaleY: 1, autoAlpha: 1, duration: 1, ease: "power2.out" },
      0
    );
  },
};
</script>
