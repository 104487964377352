<template>
  <div class="location-filter">
    <vSelect
      v-model="currentCity"
      :options="options"
      label="name"
      placeholder="Kies een stad"
      @input="onSelectCity"
    >
    </vSelect>
    <div class="location-filter__buttons">
      <a
        v-for="location in optionChildren"
        :key="location.id"
        class="btn btn--location"
        :class="{ active: location.slug === currentLocationParam }"
        @click="onSelectLocation(location)"
      >
        {{ location.name }}
      </a>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  name: "LocationFilter",
  components: {
    vSelect,
  },
  props: {
    locations: {
      type: Array,
      required: true,
    },
    currentCityParam: {
      type: String,
      required: false,
      default: null,
    },
    currentLocationParam: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      currentCity: null,
      currentLocation: null,
    };
  },
  computed: {
    options() {
      // Get only top levels: the cities.
      return this.locations.filter((x) => x.level === 1);
    },
    optionChildren() {
      // Show the children of the selected option: the locations.
      if (!this.currentCity) return null;
      return this.locations.filter((x) => x.parent === this.currentCity.slug);
    },
  },
  mounted() {
    this.setCurrents();
  },
  methods: {
    setCurrents() {
      if (!this.locations) return;

      // Set current city if the URL parameter is set
      if (this.currentCityParam) {
        this.currentCity = this.locations.find(
          (x) => x.slug === this.currentCityParam
        );
      }

      // Set current location if the URL parameter is set
      if (this.currentLocationParam) {
        this.currentLocation = this.locations.find(
          (x) => x.slug === this.currentLocationParam
        );
      }
    },
    onSelectCity(event) {
      if (!event) {
        return this.onClear();
      }
      // Select city
      this.currentCity = event;
      window.location.href = `?city=${event.slug}`;
    },
    onSelectLocation(location) {
      // If selected, deselect
      if (this.currentLocation && location.slug === this.currentLocation.slug) {
        return (window.location.href = `?city=${location.parent}`);
      }
      // select location
      return (window.location.href = `?city=${location.parent}&location=${location.slug}`);
    },
    onClear() {
      window.location.href = `?`;
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
