<template>
  <button
    class="navigation-button"
    :class="{
      'navigation-button--open': isOpen,
    }"
    @click="open()"
  >
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>

<script>
import EventBus from "./../services/event-bus";

export default {
  name: "NavigationButtonMobile",
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    EventBus.$on("toggleMenu", (isOpen) => {
      this.isOpen = isOpen;
    });
  },
  methods: {
    open() {
      EventBus.$emit("toggleMenu", !this.isOpen);
    },
  },
};
</script>
