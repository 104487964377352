<template>
  <div class="events">
    <h1>Evenementen</h1>
    <LoadingState v-if="loading" />
    <ServerErrorState v-if="error" :error-obj="error" />
    <div
      v-for="(event, eventIndex) in events.items"
      :key="eventIndex"
      class="events__event-card"
    >
      <h2>{{ event.title }}</h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="event.description"></div>
    </div>
  </div>
</template>

<script>
import { foysApi } from "../services/api";
import LoadingState from "./LoadingState.vue";
import ServerErrorState from "./ServerErrorState.vue";

export default {
  name: "EventsOverviewList",
  components: {
    LoadingState,
    ServerErrorState,
  },
  data: () => ({
    events: null,
    loading: false,
    error: false,
    endDate: "2022-10-15",
  }),
  computed: {
    currentDate() {
      return new Date().toISOString().split("T")[0];
    },
  },
  async created() {
    await this.getAllEvents();
  },
  methods: {
    async getAllEvents() {
      this.error = false;
      this.loading = true;

      const endpoint = `${foysApi.endpoints.events.getAll}&start=${this.currentDate}&end=${this.endDate}`;

      await foysApi
        .get(endpoint)
        .then((res) => (this.events = res.data))
        .catch((err) => (this.error = err.response))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.events {
  margin: 5rem auto 2rem auto;
  padding: 1rem;
  max-width: 1000px;

  h1 {
    margin-bottom: 2rem;
  }

  &__event-card {
    margin-bottom: 2rem;
  }
}
</style>
