<template>
  <div ref="text">
    <slot></slot>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "AnimatedHeroContent",
  mounted() {
    this.setupTimeline();
  },
  methods: {
    setupTimeline() {
      const timeline = gsap.timeline();

      timeline.fromTo(
        this.$refs.text,
        { autoAlpha: 0, x: -40 },
        {
          autoAlpha: 1,
          x: 0,
          ease: "power1.out",
          duration: 1,
        },
        0.2
      );
    },
  },
};
</script>
