<template>
  <div class="configurator-step">
    <h3 class="configurator-step__question">
      <slot name="question" />
    </h3>
    <slot name="skip" />
    <ul class="configurator-step__answers">
      <slot name="answers" />
    </ul>
  </div>
</template>

<script>
export default {
  name: "ConfiguratorWidgetStep",
};
</script>
