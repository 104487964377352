<template>
  <button :class="[classes]" @click="open()">
    <slot>
      {{ buttonText }}
    </slot>
  </button>
</template>

<script>
import EventBus from "../services/event-bus";
export default {
  name: "OpenConfiguratorButton",
  props: {
    buttonText: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: null,
    },
    configuratorData: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  methods: {
    open() {
      EventBus.$emit("toggleConfigurator");
      EventBus.$emit("configuratorData", this.configuratorData);
    },
  },
};
</script>
