<template>
  <div ref="wrapper" class="animated-list">
    <span ref="text" class="animated-list__text">{{ index }}</span>
    <slot></slot>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "AnimatedList",
  props: {
    index: {
      type: Number,
      required: true,
    },
    config: {
      type: Object,
      required: true,
      reverseAnimationDirection: {
        type: Boolean,
        required: false,
        default: false,
      },
      startAnimationOffset: {
        type: String,
        required: true,
      },
      delay: {
        type: Number,
        required: false,
        default: 0,
      },
    },
  },
  mounted() {
    this.setupTimeline();
  },
  methods: {
    setupTimeline() {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.wrapper,
          start: this.$props.config.startAnimationOffset,
          scrub: true,
        },
      });

      const startingY = this.$props.config.reverseAnimationDirection
        ? -250
        : 250;

      timeline.fromTo(
        this.$refs.text,
        { y: startingY, opacity: 0 },
        {
          y: () => -ScrollTrigger.maxScroll(this.$refs.wrapper) * 0.2,
          opacity: 1,
          delay: this.$props.config.delay,
        }
      );
    },
  },
};
</script>
