<template>
  <div class="video-player" :class="{ playing }">
    <video
      ref="video"
      :width="settings.width"
      :height="settings.height"
      v-bind="settings.options"
      :poster="settings.poster"
    >
      <source :src="settings.url" :type="`video/${settings.extension}`" />
      Your browser does not support the video tag.
    </video>
    <button class="btn btn--play" @click="play"></button>
  </div>
</template>

<script>
import { isTouchDevice } from "../services/helpers";

export default {
  name: "VideoPlayer",
  props: {
    settings: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data: () => ({
    playing: false,
    video: null,
  }),
  mounted() {
    this.video = this.$refs.video;
    this.video.addEventListener("pause", () => {
      this.pause();
    });

    this.watchVisibility();
  },

  methods: {
    // Auto-play/pause video when element visibility changes
    watchVisibility() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            this.video.setAttribute("playsinline", true);
            this.video.controls = false;

            if (!entry.isIntersecting && this.playing && this.video) {
              this.video.pause();
              this.playing = false;
            } else if (entry.isIntersecting && !this.playing && this.video) {
              this.video.play();
              this.video.muted = true;
              this.playing = true;
            }
          });
        },
        { threshold: 0 }
      );
      observer.observe(this.video);
    },
    play() {
      this.playing = true;
      const { video } = this;

      video.currentTime = 0;
      video.muted = false;
      video.controls = true;

      if (isTouchDevice()) {
        video.removeAttribute("playsinline");
        video.load();
      }

      video.play();

      if (video.requestFullscreen) {
        video.requestFullscreen();
      }

      // Disabled for Safari MacOS
      // This feature is only partially supported for Safari and currently introduces bugs
      else if (video.webkitRequestFullscreen) {
        // video.webkitRequestFullscreen();
      } else if (video.webkitRequestFullscreen && isTouchDevice()) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullScreen) {
        video.msRequestFullScreen();
      }
    },
    pause() {
      document.exitFullscreen();
      if (this.settings.playsinline) this.video.playsinline = true;
    },
  },
};
</script>
