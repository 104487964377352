<template>
  <div
    v-if="showBanner"
    :ref="bannerName"
    class="global-banner"
    :class="[scrollClasses]"
    :data-name="bannerName"
  >
    <div class="global-banner__container container">
      <div class="global-banner__inner">
        <slot name="banner-content"></slot>
        <button class="global-banner__button" @click="closeBanner">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#202A2A"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "GlobalBanner",
  props: {
    bannerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      banner: null,
      showBanner: false,
      scrollClasses: null,
      endOfBanner: 0,
      loopIndex: 0,
    };
  },
  computed:{
    bannerName() {
      return `global-banner--${this.bannerId}`.toLocaleLowerCase();
    },
  },
  mounted() {
    this.endOfBanner = 0;
    this.checkBannerDisplayState();
    this.setEventlistners();
    this.setDefaultValues();
    this.setBannerStickyState();
  },
  methods: {
    closeBanner() {
      this.showBanner = false;
      sessionStorage.setItem(this.bannerName, false);
    },
    checkBannerDisplayState() {
      if (sessionStorage.getItem(this.bannerName)) {
        this.showBanner = sessionStorage.getItem(this.bannerName) === "true";
        return;
      }

      this.showBanner = true;
    },
    setEventlistners() {
      window.addEventListener("scroll", debounce(this.handleScroll, 100));
    },
    handleScroll() {
      this.banner = this.banner || this.$refs[this.bannerName];
      if (!this.banner || !this.banner.offsetHeight) {
        return;
      }

      this.endOfBanner = this.banner.offsetHeight;
      this.setBannerStickyState();
      this.setPageComponentStates();
    },
    setBannerStickyState() {
      if (window.scrollY > this.endOfBanner) {
        return (this.scrollClasses = "global-banner--sticky");
      }
      this.scrollClasses = "global-banner--relative";
    },
    setPageComponentStates() {
      const headerMenu = document.querySelector(".header-menu");
      const floatingCta = document.querySelector(".floating-cta");

      if (!headerMenu || !floatingCta) {
        return;
      }

      if (this.showBanner) {
        headerMenu.style.marginTop = `${this.endOfBanner}px`;
        floatingCta.style.top = `${this.endOfBanner}px`;
      } else {
        headerMenu.style.marginTop = "0px";
        floatingCta.style.top = "0px";
      }
    },
    setDefaultValues() {
      this.handleScroll();
    },
  },
};
</script>
