<template>
  <div :ref="`location-slider`" class="glide">
    <div class="glide__arrows" data-glide-el="controls">
      <button
        class="glide__control glide__arrow--prev"
        data-glide-dir="<"
        name="move slider to the left"
      ></button>
      <button
        class="glide__control glide__arrow--next"
        data-glide-dir=">"
        name="move slider to the right"
      ></button>
    </div>

    <div class="glide__track lslider-glide-track" data-glide-el="track">
      <div class="glide__slides lslider-glide-slides">
        <div v-for="i in totalNumberOfSlides" :key="i" class="glide__slide">
          <slot :name="'slide_' + i"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Glide from "@glidejs/glide";

export default {
  name: "LocationSlider",
  props: {
    totalItems: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data: () => ({
    glide: null,
  }),
  computed: {
    totalNumberOfSlides() {
      return parseInt(this.totalItems);
    },
  },
  mounted() {
    this.glide = new Glide(this.$refs[`location-slider`], {
      type: "carousel",
      perView: 6,
      gap: 20,
      peek: { before: 173, after: -50 },
      breakpoints: {
        1300: {
          perView: 5,
          peek: { before: 173, after: -50 },
        },
        1024: {
          perView: 4,
          peek: { before: 64, after: -50 },
        },
        768: {
          perView: 2,
          peek: { before: 32, after: -50 },
        },
        600: {
          perView: 1,
          peek: { before: 32, after: 150 },
        },
      },
    });

    const resizeEvent = new Event("resize");
    window.dispatchEvent(resizeEvent);
    this.glide.mount();
  },
};
</script>
