<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="cb cb-prices-block prices-block container">
    <div class="prices-block__container">
      <div class="prices-block__title">
        <div class="pp-section-title">
          {{ content.title }}
        </div>
      </div>

      <div class="prices-block__outer">
        <!-- Controls -->
        <div class="prices-block__tabs">
          <div
            class="prices-block__tabs--1"
            :class="{ active: activeTab === 1 }"
            @click="toggleActiveTab(1)"
          >
            <span class="text-raw" v-html="content.tab1.title"></span>
          </div>

          <div
            class="prices-block__tabs--2"
            :class="{ active: activeTab === 2 }"
            @click="activeTab = 2"
          >
            <span class="text-raw" v-html="content.tab2.title"></span>
          </div>
        </div>

        <!-- Tab content -->
        <div class="prices-block__tab-content">
          <div class="prices-block__tab-content--left">
            <table class="prices-block__table">
              <thead
                v-if="activeTabContent.adjustableTableFields"
                class="prices-block__t-head"
              >
                <th class="prices-block__table-header">Data</th>
                <th
                  v-for="(
                    item, index
                  ) in activeTabContent.adjustableTableFields"
                  :key="`tab-header-${index}`"
                  class="prices-block__table-header"
                >
                  {{ item.header }}
                </th>
              </thead>
              <thead v-else class="prices-block__t-head">
                <th class="prices-block__table-header">Data</th>
                <th class="prices-block__table-header">Binnen</th>
                <th class="prices-block__table-header">Buiten</th>
              </thead>

              <tbody
                v-if="activeTabContent.adjustableTableFields"
                class="prices-block__t-body"
              >
                <td class="prices-block__table-text">
                  <div class="prices-block__table-text-container">
                    <slot name="clock" />
                    Per uur
                  </div>
                </td>
                <td
                  v-for="(
                    item, index
                  ) in activeTabContent.adjustableTableFields"
                  :key="`tab-data-${index}`"
                  class="prices-block__table-text bigger"
                >
                  {{ item.data }}
                </td>
              </tbody>

              <tbody v-else class="prices-block__t-body">
                <td class="prices-block__table-text">
                  <div class="prices-block__table-text-container">
                    <slot name="clock" />
                    Per uur
                  </div>
                </td>
                <td class="prices-block__table-text bigger">
                  € {{ activeTabContent.table[0].col1 }}
                </td>
                <td class="prices-block__table-text bigger">
                  € {{ activeTabContent.table[0].col2 }}
                </td>
              </tbody>
            </table>

            <div class="prices-block__info">
              <div class="prices-block__info-header">
                <slot name="info" />
                Informatie
              </div>
              <div
                class="prices-block__info-text redactor"
                v-html="activeTabContent.info"
              ></div>
            </div>
          </div>

          <div class="prices-block__tab-content--right">
            <slot name="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricesBlock",
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  computed: {
    activeTabContent() {
      return this.content[`tab${this.activeTab}`];
    },
  },
  methods: {
    toggleActiveTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
