<template>
  <div class="blog-detail">
    <LoadingState v-if="loading" />
    <ServerErrorState v-if="error" :error-obj="error" />
    <img :src="currentBlogItem.attachments[0].url" alt="img alt tag joe" />
    <h1 v-if="currentBlogItem.translations[0].title">
      {{ currentBlogItem.translations[0].title }}
    </h1>

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="currentBlogItem.translations[0].body"
      v-html="currentBlogItem.translations[0].body"
    ></div>
  </div>
</template>

<script>
import { foysApi } from "../services/api";
import LoadingState from "./LoadingState.vue";
import { parseFoysBlogItem } from "../services/utils";
import ServerErrorState from "./ServerErrorState.vue";

export default {
  name: "BlogDetailPage",
  components: {
    LoadingState,
    ServerErrorState,
  },
  props: {
    slug: {
      type: String,
      required: true,
      default: "",
    },
  },
  data: () => ({
    allBlogItems: null,
    loading: false,
    error: false,
    // currBlogItem: null
  }),
  computed: {
    currentBlogItem() {
      if (this.allBlogItems) {
        const match = this.allBlogItems.filter(
          (item) => item.slug === this.slug
        );
        return match && match.length ? match[0] : null;
      }

      return null;
    },
  },
  async created() {
    await this.getAllBlogItems();
    // Currently unused because the "get all blog items" call returns the same data as the detail page call.
    // .then(async () => await this.getSingleBlogItem(this.currentBlogItem.id))
  },
  methods: {
    async getAllBlogItems() {
      this.error = false;
      this.loading = true;

      await foysApi
        .get(foysApi.endpoints.news.getAll)
        .then(
          (res) =>
            (this.allBlogItems = res.data
              ? res.data.map(parseFoysBlogItem)
              : null)
        )
        .catch((err) => (this.error = err.response))
        .finally(() => (this.loading = false));
    },
    async getSingleBlogItem(id) {
      this.error = false;
      this.loading = true;

      await foysApi
        .get(foysApi.endpoints.news.getSingle(id))
        .then((res) => (this.currBlogItem = res.data))
        .catch((err) => (this.error = err.response))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.blog-detail {
  margin: 5rem auto 2rem auto;
  padding: 1rem;
  max-width: 1000px;

  h1 {
    margin-bottom: 2rem;
  }
}
</style>
