<template>
  <li class="header-menu__item header-menu__item--static">
    <a href="/reserveren/profiel" class="header-menu__link">
      <slot name="user"></slot>
      <span class="header-menu__item--login">{{ foysUsername }}</span>
    </a>
  </li>
</template>

<script>
export default {
  name: "FoysLoginButton",
  props: {
    activeLocalStorageWatcherEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      foysPublicAccessToken: null,
      foysUserData: null,
      foysUsername: "Login",
    };
  },
  mounted() {
    this.getFoysData();
    this.watchLocalStorage();
  },
  methods: {
    watchLocalStorage() {
      if (this.activeLocalStorageWatcherEnabled) {
        setInterval(() => {
          this.getFoysData();
        }, 1000);
      }
    },
    getFoysData() {
      this.foysPublicAccessToken = this.getFoysPublicAccessToken();
      this.foysUserData = this.getFoysUserData(this.foysPublicAccessToken);
      this.foysUsername =
        this.foysUserData && Object.hasOwn(this.foysUserData, "Name")
          ? this.foysUserData.Name
          : "Login";
    },
    getFoysPublicAccessToken() {
      let foysPublicAccessToken = localStorage.getItem("publicToken");
      if (foysPublicAccessToken) {
        foysPublicAccessToken = JSON.parse(foysPublicAccessToken);
        foysPublicAccessToken =
          foysPublicAccessToken.authentication.publicAccessToken ?? null;
      }
      return foysPublicAccessToken;
    },
    getFoysUserData(token) {
      if (!token) {
        return;
      }
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
  },
};
</script>
