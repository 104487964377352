<template>
  <div class="location-slide-counter">
    <div class="location-slide-counter__inner">
      <span class="location-slide-counter__current">{{ currentSlide }}</span>
      <span>/</span>
      <span class="location-slide-counter__limit">{{ limit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationSlideCounter",
  props: {
    limit: {
      type: String,
      default: "0",
      required: true,
    },
    currentSlide: {
      type: Number,
      default: 1,
      required: true,
    },
  },
};
</script>
