import axios from "axios";
import { foysApiEndpoints, foysApiConfig } from "../../config/api.config";
import "@lottiefiles/lottie-player";

/**
 * Base (default api) Axios instance.
 */
const instance = axios.create(foysApiConfig.axios);

/**
 * Default method for setting request auth configuration.
 */
const setAuthHeader = () => {
  instance.defaults.headers.common.Authorization = process.env
    .VUE_APP_FOYS_API_KEY
    ? `Bearer ${process.env.VUE_APP_FOYS_API_KEY}`
    : "";
};

/**
 * Main API interface for this app.
 *
 * Spreading out the instance objects, enables us to use instance methods
 * by calling them through this wrapper object.
 *
 * @example api.post()
 * @example api.get()
 */
export const foysApi = {
  setAuthHeader,
  endpoints: foysApiEndpoints,
  ...instance,
};
