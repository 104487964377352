/**
 * Foys API instance configuration.
 *
 * @type {Object}
 */
export const foysApiConfig = {
  axios: {
    baseURL: process.env.VUE_APP_FOYS_API_ENDPOINT,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
};

/**
 * Foys API endpoints and request definitions.
 *
 * @type {Object}
 */
export const foysApiEndpoints = {
  events: {
    /**
     * Get all public events
     *
     * @description call: GET
     *
     * @param start - range start date; format yyyy-mm-dd
     * @param end   - range end date; format yyyy-mm-dd
     * @param city  - city filter
     */
    getAll:
      "v2/pub/public-calendar?organisationId=df82f4dd-fd87-4af5-9c2f-656fe1a44357",
  },

  news: {
    /**
     * Get all news items.
     *
     * @description call: GET
     */
    getAll:
      "v2/pub/organisations/df82f4dd-fd87-4af5-9c2f-656fe1a44357/news-items",

    /**
     * Get a single news item by id.
     *
     * @description call: GET
     *
     * @param id - the id of the news item.
     */
    getSingle: (id) =>
      `v2/pub/organisations/df82f4dd-fd87-4af5-9c2f-656fe1a44357/news-items/${id}`,
  },
};
