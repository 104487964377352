<template>
  <div ref="container" class="parallax-container">
    <slot></slot>
  </div>
</template>

<script>
import Rellax from "rellax";
import { isTouchDevice } from "../services/helpers";

export default {
  name: "ParallaxContainer",
  props: {
    config: {
      type: Object,
      required: true,
    },
    breakpoint: {
      type: String,
      required: false,
      default: "0px",
    },
  },
  data: () => ({
    parallax: null,
    isNarrow: false,
  }),
  mounted() {
    this.setupParallax();
    this.resize();

    window.addEventListener("resize", () => {
      this.resize();
    });
  },
  methods: {
    setupParallax() {
      if (!isTouchDevice() && this.$refs.container) {
        this.parallax = new Rellax(this.$refs.container, this.$props.config);

        window.addEventListener("scroll", () => {
          if (!this.isNarrow) {
            this.refreshParallax();
          }
        });
      }
    },
    removeParallax() {
      if (this.parallax) {
        this.parallax.destroy();
      }
    },
    refreshParallax() {
      if (this.parallax) {
        this.parallax.refresh();
      }
    },
    matchesBreakpoint(breakpoint) {
      return window.matchMedia(breakpoint).matches;
    },
    resize() {
      this.isNarrow = !this.matchesBreakpoint(
        `(min-width: ${this.$props.breakpoint})`
      );

      if (!isTouchDevice() && this.isNarrow) {
        this.removeParallax();
      } else if (!isTouchDevice() && !this.isNarrow) {
        this.refreshParallax();
      }
    },
  },
};
</script>
