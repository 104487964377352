<template>
  <div class="header-container">
    <slot></slot>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "GlobalHeader",
  mounted() {
    const logo = document.querySelector(".header-menu__logo");
    const menuItems = document.querySelectorAll(".header-menu__item");

    if (!logo || !menuItems) {
      return;
    }

    const timeline = gsap.timeline();

    timeline.fromTo(
      logo,
      { y: -20, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, ease: "power1.out" },
      0.6
    );

    timeline.fromTo(
      menuItems,
      { y: -20, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 0.8,
        stagger: 0.1,
        ease: "power2.out",
      },
      0.8
    );
  },
};
</script>
