<template>
  <div ref="wrapper" class="animated-quote" start-animation-offset="0">
    <div ref="text" class="animated-quote__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "AnimatedQuote",
  props: {
    startAnimationOffset: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.setupTimeline();
  },
  methods: {
    setupTimeline() {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.wrapper,
          start: this.$props.startAnimationOffset,
        },
      });

      timeline.fromTo(
        this.$refs.text,
        { autoAlpha: 0, y: 40 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.5,
        },
        0
      );
    },
  },
};
</script>
