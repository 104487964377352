<template>
  <div v-if="events">
    <h1>Featured Content Duo</h1>
    <div v-for="(event, eventIndex) in events.items" :key="eventIndex">
      <!-- Only show the first two results -->
      <div v-if="eventIndex <= 1">
        <h1>{{ event.title }}</h1>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import { foysApi } from "../services/api/index";

export default {
  name: "FeaturedContentDuo",
  props: {
    // location of the parent (event) item.
    location: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => ({
    events: null,
    loading: false,
    error: false,
    startDate: "2021-09-01",
    endDate: "2022-10-15",
  }),
  computed: {
    currentDate() {
      // .now.split('T')[0]
      return new Date().toISOString().split("T")[0];
    },
  },
  async created() {
    await this.getAllEvents();
  },
  methods: {
    async getAllEvents() {
      this.error = false;
      this.loading = true;

      const baseEndpoint = `${foysApi.endpoints.events.getAll}&start=${this.startDate}&end=${this.endDate}`;
      const endpoint = this.location
        ? `${baseEndpoint}&city=${this.location}`
        : baseEndpoint;

      await foysApi
        .get(endpoint)
        .then((res) => (this.events = res.data))
        .catch((err) => (this.error = err.response))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
