<template>
  <div>
    <h2>LOADING...</h2>
  </div>
</template>

<script>
export default {
  name: "LoadingState",
};
</script>
