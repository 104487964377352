import slugify from "slugify";

/**
 * Turns a string into a slug (using the npm slugify package).
 *
 * @param str {string}
 *
 * @return {string}
 */
export const strToSlug = (str = "") =>
  slugify(str, {
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
  });

/**
 * Parses an item from the Foys "news-items" API call and adds some missing properties.
 *
 * @param item {Object}
 *
 * @return {Object}
 */
export const parseFoysBlogItem = (item = {}) => ({
  ...item,
  uri: `/blog/${strToSlug(item.name)}`,
  slug: strToSlug(item.name),
});
